import React from "react"
import { graphql,Link } from "gatsby"
import { Global } from "@emotion/react"
import Seo from "../components/seo"
//import { SectionWrap } from "../components/styles"
//import Hero from "../components/Hero"
import Article from "../components/Article.js"
import theme from "../gatsby-plugin-theme-ui"
import { SectionWrap } from "../components/styles"
import { Header, Hero, Section } from "../components"
import { globalStyles } from "../templates/styles"
import { Themed } from "theme-ui"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import AppStores from "../components/AppStores.js"
import SearchBox from "../components/SeachBox.js"
import TestKnowledge from "../components/TestKnowledge"
import { gameIndex } from "../inGame"
function getLastPartOfUrl(url) {
  const parsedUrl = new URL(url);
  const pathname = parsedUrl.pathname;
  const parts = pathname?.split('/');
  const lastPart = parts.pop() || parts.pop(); // Handle trailing slash
  return lastPart;
}
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { nounsJson, allAudioListJson } = data
  const { definitionsArray, title } = nounsJson
  const hasAudio = allAudioListJson?.nodes?.[0]?.word ===title;


  var colors = {
    f: "#ff2b2b",
    m: "#40a840",
    n: "#0084ff",
  }
  var unabbreviated = {
    m: "der",
    f: "die",
    n: "das",
  }

  var genderWord = {
    m: "maskulin",
    f: "feminin",
    n: "neutral",
  }

  var descriptionNumber = definitionsArray[0]
  const article2Description =
    descriptionNumber.gender && descriptionNumber.gender[1]
      ? unabbreviated[descriptionNumber.gender[1]]
      : ""
  const article3Description =
    descriptionNumber.gender && descriptionNumber.gender[2]
      ? unabbreviated[descriptionNumber.gender[2]]
      : ""
  const genderNoteDescription = descriptionNumber.genderNote
    ? descriptionNumber.genderNote
    : ""
  const genderNoteDescriptionTrim = genderNoteDescription.slice(
    0,
    genderNoteDescription.indexOf("[")
  )

  var descriptionBuild = descriptionNumber.gender
    ? `Das Wort ${title} ist (grammatisches Geschlecht/Genus) ${
        genderWord[descriptionNumber.gender[0]]
      }${
        descriptionNumber.gender[1] && !descriptionNumber.gender[2]
          ? " oder " + genderWord[descriptionNumber.gender[1]]
          : descriptionNumber.gender[1]
          ? ", " + genderWord[descriptionNumber.gender[1]]
          : ""
      }${
        descriptionNumber.gender[1] && descriptionNumber.gender[2]
          ? " oder " + genderWord[descriptionNumber.gender[2]]
          : ""
      }, deshalb ist der richtige Artikel in der Grundform  ${
        unabbreviated[descriptionNumber.gender[0]]
      }${
        descriptionNumber.gender[1] && !descriptionNumber.gender[2]
          ? " oder "
          : descriptionNumber.gender[1]
          ? `, `
          : ""
      }${
        descriptionNumber.gender[1] && !descriptionNumber.gender[2]
          ? article2Description
          : article2Description
      }${
        descriptionNumber.gender[1] && descriptionNumber.gender[2]
          ? " oder "
          : ""
      }${
        descriptionNumber.gender[1] && descriptionNumber.gender[2]
          ? article3Description
          : ""
      }.`
    : ""

  const wordForQuiz = gameIndex.splice(
    Math.floor(Math.random() * gameIndex.length),
    1
  )[0]

  return (
    <div style={{}}>
      <Seo
        title={` Heißt es der, die oder das ${title}?`}
        rawTitle={title}
        description={`Welche Artikel hat ${title} auf Deutsch? ${descriptionBuild} ${genderNoteDescriptionTrim}`}
        isDefinition={true}
        lang={"de"}
        canonical={`de/${title.replace(/ /g, "_")}`}
        audio={
          definitionsArray[0] && definitionsArray[0].audio?.length
            ? definitionsArray[0].audio[0].src
            : null
        }
        keywords={`${title},der,die,das,artikel,feminin,maskulin,neutrum,grammatik,lernen,deutsch,substantive,training,spiel,substantiv,substantiv,vokabular,ios,android,app,deklination, plural, alle formen, nominativ`}
      />
      <Global styles={globalStyles} />
      <Header prefix="de" logoTxt={"Der Die Das Train"} />
      <SectionWrap style={{ backgroundColor: "white" }}>
        <Hero imageAlt={"hero.imageAlt"} id="hero" />
        <article style={{}}>
          <SearchBox prefix="de"></SearchBox>

          {definitionsArray.length
            ? definitionsArray.map((number, index) => {
                const definitionText = number.definitions
                  ? number.definitions?.split("\n").map((str, i) => (
                      <Themed.p key={i} style={{ margin: 0 }}>
                        {str}
                      </Themed.p>
                    ))
                  : ""
                const examplesText = number.examples
                  ? number.examples?.split("\n").map((str, i) => (
                      <Themed.p key={i} style={{ margin: 0 }}>
                        {str}
                      </Themed.p>
                    ))
                  : ""

                const article2 =
                  number.gender && number.gender[1] ? (
                    <Themed.strong>
                      {unabbreviated[number.gender[1]]}
                    </Themed.strong>
                  ) : null
                const article3 =
                  number.gender && number.gender[2] ? (
                    <Themed.strong>
                      {unabbreviated[number.gender[2]]}
                    </Themed.strong>
                  ) : null
                return (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      padding: 20,
                    }}
                  >
                    <div
                      style={{
                        height: "auto",
                        maxWidth: 1024,
                        backgroundColor: "white",
                        marginBottom: "2em",
                        width: "100%",
                      }}
                      backgroundColor={theme.colors.white}
                    >
                      <Themed.h1 style={{ fontWeight: "700" }}>
                        Heißt es der, die oder das {title}?
                      </Themed.h1>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        {number.gender && number.gender.length ? (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            {number.gender.map((gender, i) => {
                              return <Article key={i} gender={gender} />
                            })}
                          </div>
                        ) : null}
                        <Themed.h2 style={{ margin: 0 }}>{title}</Themed.h2>
                        {hasAudio && (
                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                            }}
                            onKeyDown={ev => {
                              if (ev.key === "Enter" || ev.key === "Spacebar") {
                                document.getElementById("audio-file-mp3").play()
                              }
                            }}
                            onClick={() => {
                              console.error(
                                document.getElementById("audio-file-mp3")
                              )
                              document.getElementById(`audio-file-mp3`).play()
                            }}
                            role="button"
                          >
                            <SpeakerDiv>
                              <StaticImage
                                style={{
                                  width: 40,
                                  height: 40,
                                  marginLeft: "1rem",
                                }}
                                src={"../images/speaker.png"}
                                height={40}
                              ></StaticImage>
                            </SpeakerDiv>
                            <audio id={"audio-file-mp3"}>
                              <source
                                src={`https://der-die-das-train.com/api/audio?file=${title}.mp3`}
                                type="audio/mp3"
                              />
                              <track default kind="descriptions" />
                            </audio>
                          </div>
                        )}
                      </div>

                      {number.gender ? (
                        <Themed.p>
                          Es heißt {unabbreviated[number.gender[0]]}
                          {number.gender[1] && !number.gender[2]
                            ? " oder " + unabbreviated[number.gender[1]]
                            : number.gender[1]
                            ? ", " + unabbreviated[number.gender[1]]
                            : ""}{" "}
                          {number.gender[1] && number.gender[2]
                            ? "oder " + unabbreviated[number.gender[2]]
                            : ""}{" "}
                          {title}!
                        </Themed.p>
                      ) : null}
                      {number.gender ? (
                        <Themed.p>
                          Das Wort <Themed.strong>{title}</Themed.strong> ist{" "}
                          {genderWord[number.gender[0]]}
                          {number.gender[1] && !number.gender[2]
                            ? " oder " + genderWord[number.gender[1]]
                            : number.gender[1]
                            ? ", " + genderWord[number.gender[1]]
                            : ""}
                          {number.gender[1] && number.gender[2]
                            ? " oder " + genderWord[number.gender[2]]
                            : ""}
                          , deshalb ist der richtige Artikel in der Grundform{" "}
                          {
                            <Themed.strong>
                              {unabbreviated[number.gender[0]]}
                            </Themed.strong>
                          }
                          {number.gender[1] && !number.gender[2]
                            ? " oder "
                            : number.gender[1]
                            ? `, `
                            : ""}
                          {number.gender[1] && !number.gender[2]
                            ? article2
                            : article2}
                          {number.gender[1] && number.gender[2] ? " oder " : ""}
                          {number.gender[1] && number.gender[2] ? article3 : ""}
                          .
                        </Themed.p>
                      ) : null}
                      <Themed.p style={{}}>{number.genderNote}</Themed.p>
                      {definitionsArray.length > 1 ? (
                        <Themed.h5
                          style={{ color: "#53575A" }}
                        >{`Anwendungsfall ${index + 1}`}</Themed.h5>
                      ) : null}

                      {index === 0 && (
                         <div >
                         <div style={{marginBottom:"2rem"}}>
                         <Link  to={'/articles/der-die-das-gender-rules'}>Der ultimative Leitfaden für deutsche Artikel: Wie man Der, Die und Das korrekt verwendet (Die Regeln und wann sie gelten)(Englisch)</Link> 

                         </div>
                        <TestKnowledge wordForQuiz={wordForQuiz} lang={"de"} />
                        </div>
                      )}

                      <Themed.h2>Wie dekliniert man {title}?</Themed.h2>
                      <Themed.p>
                        Wie funktioniert die Deklination von {title}? Hier
                        findest du alle Formen im Singular sowie im Plural:
                      </Themed.p>
                      <div id={"table-outer"}>
                        <div
                          style={{ overflowX: "scroll", maxWidth: "90%",  scrollbarWidth:'none' }}
                          dangerouslySetInnerHTML={{
                            __html: `${tableStyle}${number.table}`,
                          }}
                        ></div>
                      </div>
                      <Themed.h3 style={{ color: "#53575A" }}>
                        {number?.definitions?.includes("[2]")
                          ? "Definitionen"
                          : "Definition"}
                      </Themed.h3>
                      {definitionText}
                      <Themed.h3 style={{ color: "#53575A" }}>
                        {"Beispielsätze"}
                      </Themed.h3>
                      {examplesText}

                   
                      {definitionsArray[0] &&
                      definitionsArray[0].images?.length &&
                      index === definitionsArray.length - 1 ? (
                        <Themed.h3>Bilder oder Fotos von {title}</Themed.h3>
                      ) : null}
                    </div>
                  </div>
                )
              })
            : null}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                height: "auto",
                maxWidth: 1024,
                backgroundColor: "white",
              }}
              backgroundColor={theme.colors.white}
            >
              <div
                backgroundColor={theme.colors.white}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 20,
                }}
                id="work"
              >
                {definitionsArray[0] && definitionsArray[0].images?.length
                  ? definitionsArray[0].images.map((number, index) => (
                      <div
                        style={{
                          width: 300 * 0.76,
                          margin: 10,
                          display: "flex",
                          flexDirection: "column",
                        }}
                        key={JSON.stringify(number) + index}
                      >
                        <img
                          alt={`${number.txt ? number.txt : title}`}
                          style={{ width: 300 * 0.75, height: 300 * 0.75 }}
                          src={'https://der-die-das-train.com/api/image?file='+getLastPartOfUrl(number.img).replace("220px-",'')}
                        ></img>
                        <div
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            marginTop: 10,
                            backgroundColor: "rgba(238, 237, 237, 0.3)",
                            padding: 10,
                            borderRadius: 10,
                          }}
                        >
                          <div>{number.txt ? number.txt : null}</div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </article>
        <Section
          backgroundColor={theme.colors.secondary}
          id="about"
          light
          enableWave={true}
          waveBottom={false}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "10rem",
              marginBottom: "5rem",
            }}
          >
            <AppStores prefix="de"></AppStores>
        
          </div>
          <section style={{display:'flex', justifyContent:'center', width:'100%', paddingInline:'1rem'}}>
    
            <Link  style={{color:'white', margin:'0.5rem'}} to={"/privacy-policy"}>Privacy Policy</Link> 
            <Link  style={{color:'white',margin:'0.5rem'}} to={"/terms-and-conditions/"}>Terms and Conditions</Link>

    
    
              <span style={{margin:'0.5rem'}}>
                {` `}© {new Date().getFullYear()}
                {` `}
              </span>
              <span style={{ fontWeight: "bold",margin:'0.5rem' }} >
                Der Die Das Train
              </span>
            </section>
        </Section>
      </SectionWrap>
    </div>
  )
}
export const pageQuery = graphql`
  query ($slug: String!) {
    nounsJson(title: { eq: $slug }) {
      title
      warning
      timestamp
      hasDefinitions
      definitionsArray {
        table
        definitions
        examples
        genderNote
        gender
        images {
          img
          txt
        }
        related {
          href
          text
        }
        audio {
          src
          txt
        }
      }
    }

        allAudioListJson(filter: {word: {eq: $slug}}) {
    nodes {
      word
    }
  }
  }
`

var tableStyle = `<style>
#table-outer {
  margin-bottom: 0em;
}

.wikitable > tr > th, .wikitable > * > tr > th {
  background-color: #eaecf0;
  text-align: center;
}

.wikitable > tr > th, .wikitable > tr > td, .wikitable > * > tr > th, .wikitable > * > tr > td {
  border: 1px solid #a2a9b1;
  padding: 0.2em 0.4em;
}
th {

  font-weight: bold;
  text-align: -internal-center;
}
.wikitable {
  width:auto;
  color: #202122;
 /* border: 1px solid #a2a9b1;*/
  border-collapse: separate;
  border-radius: 10px !important;


}
table {
  font-size: 100%;
  max-width:100%;
  width:auto;
}
@media only screen and (max-width: 600px) {
  #table-outer {
    margin-bottom: 22em;
  }
}
</style>`

const SpanText = styled.span`
  color: ${"#53575A"};
`

const SpeakerDiv = styled.div`
  background-color: ${"white"};
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  margin-bottom: 10px;
`
/*
             <Themed.p>
                        In der deutschen Sprache ist das Genus für jedes
                        Substantiv (im Lexikon) festgelegt.
                      </Themed.p>
                      <Themed.p>
                        Die deutschen Substantive gehören entweder dem Genus
                        Maskulinum (männlich, Standardgenus) mit dem definiten
                        Artikel der, dem Femininum (weiblich) mit dem definiten
                        Artikel die oder dem Neutrum (sächlich) mit dem
                        definiten Artikel das an.
                      </Themed.p>
                      <ul>
                        <Themed.li>
                          für Maskulina: Himmelsrichtungen, Witterungen (Osten,
                          Monsun, Sturm; aber: das Gewitter), Spirituosen
                          (Wodka, Wein, Kognak), Mineralien, Gesteine (Marmor,
                          Quarz, Granit, Diamant);
                        </Themed.li>

                        <Themed.li>
                          für Feminina: Schiffe und Flugzeuge (die Deutschland,
                          die Boeing; aber: der Airbus), Zigarettenmarken
                          (Camel, Marlboro), viele Baum- und Pflanzenarten
                          (Eiche, Pappel, Kiefer; aber: der Flieder), Zahlen
                          (Eins, Million; aber: das Dutzend), die meisten
                          inländisch entspringenden Flüsse (Elbe, Oder, Donau;
                          aber: der Rhein), …;
                        </Themed.li>

                        <Themed.li>
                          für Neutra: Cafés, Hotels, Kinos (das Mariott, das
                          Cinemaxx), chemische Elemente (Helium, Arsen; aber:
                          der Schwefel, maskuline Elemente auf -stoff),
                          Buchstaben, Noten, Sprachen und Farben (das Orange,
                          das A, das Englische), bestimmte Markennamen für
                          Wasch- und Reinigungsmittel (Ariel, Persil),
                          Kontinente, Länder (die artikellosen: (das alte)
                          Europa; aber: der Libanon, die Schweiz …).
                        </Themed.li>
                      </ul>
*/

/*   {definitionsArray[0] &&
                      definitionsArray[0].audio?.length &&
                      index === definitionsArray.length - 1 ? (
                        <Themed.h3>Wie sagt man {title}?</Themed.h3>
                      ) : null}

                      <div
                        style={{
                          backgroundColor: "white",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        {definitionsArray[0] &&
                        definitionsArray[0].audio?.length &&
                        index === definitionsArray.length - 1
                          ? definitionsArray[0].audio.map((number, index) => {
                              if (number.src) {
                                return (
                                  <div
                                    key={JSON.stringify(number) + index}
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "column",
                                      padding: "10px",
                                    }}
                                    onKeyDown={ev => {
                                      if (
                                        ev.key === "Enter" ||
                                        ev.key === "Spacebar"
                                      ) {
                                        document
                                          .getElementById(`audio${index}`)
                                          .play()
                                      }
                                    }}
                                    onClick={() =>
                                      document
                                        .getElementById(`audio${index}`)
                                        .play()
                                    }
                                    role="button"
                                    tabIndex={index}
                                  >
                                    <SpeakerDiv>
                                      <StaticImage
                                        style={{
                                          width: 40,
                                          height: 40,
                                          marginBottom: 5,
                                        }}
                                        src={"../images/speaker.png"}
                                        height={40}
                                      ></StaticImage>
                                    </SpeakerDiv>
                                    <audio id={`audio${index}`}>
                                      <source
                                        src={number.src}
                                        type="audio/mp3"
                                      />
                                      <track default kind="descriptions" />
                                    </audio>

                                    <SpanText>
                                      {number.txt ? number.txt : null}
                                    </SpanText>
                                  </div>
                                )
                              } else {
                                return <div></div>
                              }
                            })
                          : null}{" "}
                      </div>*/